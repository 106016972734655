:root {
  --font-color: #fff;
  --primary-color: #00FF41;
}

.primary-color {
  color: #00FF41;
}

.App {
  text-align: center;
}

body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body,
html {
  background-color: #000;
}

.App {
  background-color: #000;
  min-height: 100vh;
}

#about {
  text-align: left;
  padding: 20px 0 0 0;
}

th,
td {
  text-align: left;
  padding: 10px;
}

.small {
  font-size: 11px;
  line-height: 12px;
}

#about h1 {
  font-size: 60px;
  line-height: 60px;
}

.terminal-card div {
  text-align: justify;
}

.terminal-card {
  margin-bottom: 50px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:768px) {
  .terminal-menu {
    display: none;
  }

  #about h1 {
    font-size: 42px;
    line-height: 42px;
  }
}